import React from "react";
import "./index.css";
import SingleBanner from "./SingleBanner";

const Banners = ({ t }) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-md-4">
          <SingleBanner t={t} />
        </div>
        <div className="col-md-4">
          <SingleBanner t={t} />
        </div>
        <div className="col-md-4">
          <SingleBanner t={t} />
        </div>
      </div>
    </div>
  );
};

export default Banners;
