import React from "react";
import "./index.css";

import { ShoppingCard } from "./ShoppingCard";

const Shoppings = ({ t }) => {
  return (
    <div>
      <div className="container-fluid p-4">
        <div className="row g-0 rowBorder">
          <div className="col-md-2"></div>
          <div className="col-md-2">
            <ShoppingCard title={t("fre")} p={t("frep")} icon="fa fa-ship" />
          </div>
          <div className="col-md-2">
            <ShoppingCard title={t("mong")} p={t("thirt")} icon="fa fa-usd" />
          </div>
          <div className="col-md-2">
            <ShoppingCard
              title={t("saf")}
              p={t("safg")}
              icon="fa fa-shopping-cart"
            />
          </div>
          <div className="col-md-2">
            <ShoppingCard
              title={t("onl")}
              p={t("wes")}
              icon="fa fa-internet-explorer"
            />
          </div>
          <div className="col-md-2"></div>
        </div>
      </div>
    </div>
  );
};

export default Shoppings;
