import React from "react";
import FooterImage from "../../../assets/images/sdf.png";
import "./index.css";

const Footer = ({ t }) => {
  return (
    <div>
      <div className="container ">
        <div className="row">
          <div className="col-md-6 ">
            <div className="row adress">
              <div className="col-md-5">
              <h5>{t("abus")}</h5>
              <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Odio recusandae nesciunt illum non commodi maiores, obcaecati, officiis necessitatibus ducimus in earum cum sapiente provident reiciendis consequuntur? Illo distinctio error veritatis.</p>
              </div>
              <div className="col-md-3 ">
                <h5>{t("addr")}</h5>
                <p>{t("taito")}</p>
                <p>{t("jap")}</p>
              </div>
              {/* <div className="col-md-5">
                <h5>{t("cus")}</h5>
                <ul>
                  <li>{t("cont")}</li>
                  <li>{t("ret")}</li>
                  <li>{t("sit")}</li>
                </ul>
              </div> */}
              <div className="col-md-4">
                <h5>{t("myAcc")}</h5>
                <ul>
                  <li>{t("bra")}</li>
                  <li>{t("gif")}</li>
                  <li>{t("aff")}</li>
                  <li>{t("spe")}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <p>{t("cop")}</p>
          </div>
          <div className="col-md-6">
            <img src={FooterImage} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
