import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { cartOperations } from "../../state/ducs/cart";
import { globalOperations } from "../../state/ducs/globalState";

const BestSellerCard=(props)=>{
  const detailsClickHandler=()=> {
    props.setSelectedProductVendorName(props.productObj.vendor_name);
  }
  const addToCartHandler = () => {
   
    // check if other vendor products available in the cart
    const other_vendor_products= props.cartItems.filter(item=> item.vendor_name !== props.productObj.vendor_name);

    if (other_vendor_products.length) {
      alert("You can order single vendor product only at a time");
      return;
    }

    detailsClickHandler();
    props.addToCart(props.productObj);
    props.showAddToCartModal();
  };

  const numberWithCommas = (x) => {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };
  return (
    <div className="newArrivalsCardContainer">
      <Link onClick={detailsClickHandler} to={"/productDetails/"+props.productObj.id}>
        <img
          src={"https://ap.japanidokan.com/assets/images/products/"+props.productObj.photo}
          class="img-fluid w-100"
          alt="Banner Image"
        ></img>
      </Link>
      <div className="tools d-flex justify-content-center">
        <i className="fa fa-heart"></i>
        <i className="fa fa-exchange"></i>
        <i className="fa fa-search"></i>
      </div>
      <div className="body-overlay">
        <div className="d-flex justify-content-center stars">
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
          <i className="fa fa-star"></i>
        </div>
        <h5 className="text-center title">
          <Link onClick={detailsClickHandler} to={"/productDetails/"+props.productObj.id}>
            {props.productObj.name.substr(0, 16)}
          </Link>
        </h5>
        <h5 className="text-center price">{`৳${numberWithCommas(
          props.productObj.price
        )}`}</h5>
        <div onClick={addToCartHandler} className="text-center addCart">
          {props.t("addc")}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return { cartItems: state.cart };
};

// Maps `dispatch` to `props`:
const mapDispatchToProps = {
  addToCart: cartOperations.addToCart,
  showAddToCartModal: globalOperations.showAddToCartModal,
  setSelectedProductVendorName: globalOperations.setSelectedProductVendorName,
};


export default connect(mapStateToProps, mapDispatchToProps)(BestSellerCard);
