import { useEffect, useState } from "react";
import Carousel from "react-bootstrap/Carousel";
import c1 from "../assets/images/c1.jpg";
import c2 from "../assets/images/c2.jpg";
import c3 from "../assets/images/c3.jpg";

function CarouselFade() {
  const [banners, setBanners] = useState([]);

  useEffect(() => {
    fetch("https://ap.japanidokan.com/api/landing")
      .then((response) => response.json())
      .then((data) => setBanners(data));
  }, []);


  return (
    <>
      {banners && banners.length ? (
        <Carousel fade>
          {banners.map((item) => (
            <Carousel.Item>
              <img className="d-block w-100" src={"https://ap.japanidokan.com/assets/images/banners/"+item.photo} alt="zgbbb" />
            </Carousel.Item>
          ))}
        </Carousel>
      ) : (
        <Carousel fade>
          <Carousel.Item>
            <img className="d-block w-100" src={c1} alt="First slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={c2} alt="Second slide" />
          </Carousel.Item>
          <Carousel.Item>
            <img className="d-block w-100" src={c3} alt="Third slide" />
          </Carousel.Item>
        </Carousel>
      )}
    </>
  );
}

export default CarouselFade;
