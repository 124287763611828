import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import "./index.css";

const TopHeader = ({ changeLn, t, setlogin, isAuthenticated }) => {
  const handleLanguageChange = (e) => {
    changeLn(e.target.value);
  };

  const navigate= useNavigate();

  const gotToMyAccount=()=> {
    navigate("/myAccount");
  }

  return (
    <>
      <div className="container top-header p-3">
        <div className="row">
          <div className="col-md-6 d-flex justify-content-start align-items-center">
            <i className="fa fa-phone"></i>
            <div className="small">
              {t("callUs")}: <strong>+880 1819250309, +8802 550 08199</strong>
            </div>
            <span className="small">|</span>
            <i className="fa fa-envelope"></i>
            <div className="small">{t("email")}: info@techknowgram.com</div>
          </div>
          <div className="col-md-6 d-flex justify-content-end align-items-center">
            <div className="mainwrap">
              <i className="fa fa-user"></i>
              <div className="small">{t("myAcc")}</div>
              {!isAuthenticated && (
                <ul className="wrap">
                  <li
                    style={{ margin: "5px", padding: "5px" }}
                    onClick={() => setlogin(true)}
                  >
                    Login
                  </li>
                  <li style={{ margin: "5px", padding: "5px" }}>Register</li>
                </ul>
              )}

              {isAuthenticated && (
                <div className="wrap">
                  <p onClick={gotToMyAccount} style={{ margin: "5px", padding: "5px" }}>My Account</p>
                </div>
              )}

              <i className="fa fa-chevron-down"></i>
            </div>

            <span className="small">|</span>
            <i className="fa fa-heart"></i>
            <div className="small">{t("wishList")} (0)</div>
            <span className="small">|</span>
            <div className="small-gap"></div>
            <span className="small">|</span>
            <select
              className="language"
              name="language"
              id="language"
              onChange={handleLanguageChange}
            >
              <option value="en">English</option>
              <option value="jp">Japanese</option>
            </select>
          </div>
        </div>
      </div>
      <div className="bb"></div>
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TopHeader);
