import React from "react";
import "./index.css";

class ProductCarousel extends React.Component {
  state = {
    photos: [],
    active: 0,
    zoomDisplay: "",
    PageX: 0,
    PageY: 0,
  };
  static getDerivedStateFromProps({ media }) {
    let photos = ["http://placecorgi.com/600/600"];

    if (media.length) {
      photos = media.map((photoObj) => photoObj);
    }

    return { photos };
  }

  handleIndexClick = (event) => {
    this.setState({
      active: +event.target.dataset.index,
    });
  };

  handleMouseOver = (event) => {
    console.log(event);
    this.setState({
      zoomDisplay: "block",
    });
  };

  handleMouseLeave = (event) => {
    this.setState({
      zoomDisplay: "",
      PageX: 0,
      PageY: 0,
    });
  };

  handleMouseMove = (event) => {
    const { left, top, width, height } = event.target.getBoundingClientRect();
    const scrollPos = window.pageYOffset;

    const x = ((event.pageX - left) / width) * 100;
    const y = ((event.pageY - scrollPos - top) / height) * 100;

    this.setState({
      PageX: x,
      PageY: y,
    });
  };
  render() {
    const { photos, active, zoomDisplay, PageX, PageY } = this.state;
    return (
      <>
        <div className="product-carousel-image-container">
          <img
            className="img-fluid w-100"
            onMouseOver={this.handleMouseOver}
            onMouseLeave={this.handleMouseLeave}
            onMouseMove={this.handleMouseMove}
            src={photos[active].large}
            alt=""
          />
          <div style={{ display: zoomDisplay }} className="zoom">
            <img
              style={{
                transform: `translate3d(${-PageX + 20}%, ${
                  -PageY + 20
                }%, ${0}px)`,
              }}
              src={photos[active].full}
              alt=""
            />
          </div>
        </div>
        <div className="navigator-container">
          <div className="container">
            <div className="row gx-2">
              {photos.map((photo, index) => (
                // eslint-disable-next-line
                <div className="col-md-3">
                  <div className="w-100">
                    <img
                      key={photo.large}
                      onClick={this.handleIndexClick}
                      data-index={index}
                      className={`img-fluid w-100 ${
                        index === active ? "active" : ""
                      }`}
                      src={photo.large}
                      alt=""
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProductCarousel;
