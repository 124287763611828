import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { connect } from "react-redux";
// import { Routes } from "react-router";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import "./App.css";
import Layout from "./Components/Layout";
import Logout from "./Components/Logout";
import AddressBook from "./Pages/AddressBook";
import CategoryDetails from "./Pages/CategoryDetails";
import CheckoutPage from "./Pages/CheckOutPage";
import Edit from "./Pages/Edit/Edit";
import ForgottenPassword from "./Pages/ForgottenPassword";
import Home from "./Pages/HomePage";
import Login from "./Pages/Login";
import MyAccounts from "./Pages/MyAccounts";
import Newslatter from "./Pages/Newslatter";
import ProdectDetails from "./Pages/ProdectDetails";
import Register from "./Pages/Register/Register";
import Search from "./Pages/Search";
import VendorDetails from "./Pages/VendorDetails";
import ViewPage from "./Pages/ViewPage";
import WishList from "./Pages/WishList";

import { authOperations } from "./state/ducs/auth";
import { authHeader } from './utils';

function App({ checkAuth, isAuthenticated, setUser }) {

  console.log("hello from App");
  const [login, setlogin] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState("");

  const [golobalKeyword, setgolobalKeyword] = useState("");

  const [homePageSearchedResultProducts, setHomePageSearchedResultProducts] = useState([]);

  const { t, i18n } = useTranslation();
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  const sectionRef = useRef(null);

  const handleSectionRef=()=> {
    sectionRef.current.scrollIntoView({ behavior: 'smooth' });
  }


  useEffect(() => {
    checkAuth();
  }, []);

  useEffect(() => {
    const url = "https://ap.japanidokan.com/api/me";

    if (isAuthenticated) {
      axios
      .get(url, {headers: authHeader()})
      .then(function (response) {
        console.log(response);
        const {data}= response;
        console.log("user data", data);
        setUser(data.user);
      })
      .catch(function (error) {
        console.log(error);
      });
    }
    
  }, [isAuthenticated]);

  console.log(isAuthenticated);

  

  let routes = (
    <Routes>
      <Route
        path="/"
        element={
          <Home
            setgolobalKeyword={setgolobalKeyword}
            searchKeyword={searchKeyword}
            setSearchKeyword={setSearchKeyword}
            t={t}
            ki={"ki Khobor"}
            login={login}
            setlogin={setlogin}
            sectionRef={sectionRef}
            homePageSearchedResultProducts={homePageSearchedResultProducts}
          />
        }
      />
      <Route path="/productDetails/:productId" element={<ProdectDetails />} />
      <Route path="/vendorDetails/:vendorId" element={<VendorDetails t={t} />} />
      <Route path="/checkout" element={<CheckoutPage />} />
      <Route path="/viewPage" element={<ViewPage />} />
      <Route
        path="/categoryDetails/:categoryId"
        element={<CategoryDetails t={t} />}
      />
      <Route path="/login" element={<Login />} />
      <Route path="/forgottenPassword" element={<ForgottenPassword />} />
      <Route path="/register" element={<Register setlogin={setlogin} />} />
      <Route
        path="/search"
        element={
          <Search
            golobalKeyword={golobalKeyword}
            searchKeyword={searchKeyword}
            t={t}
          />
        }
      />

      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );

  if (isAuthenticated) {
    routes = (
      <Routes>
        <Route
          path="/"
          element={
            <Home
            
              setgolobalKeyword={setgolobalKeyword}
              searchKeyword={searchKeyword}
              setSearchKeyword={setSearchKeyword}
              t={t}
              login={login}
              setlogin={setlogin}
              sectionRef={sectionRef}
              homePageSearchedResultProducts={homePageSearchedResultProducts}
            />
          }
        />
        <Route path="/productDetails/:productId" element={<ProdectDetails />} />
        <Route path="/vendorDetails/:vendorId" element={<VendorDetails t={t} />} />
        <Route path="/checkout" element={<CheckoutPage />} />
        <Route path="/viewPage" element={<ViewPage />} />
        <Route
          path="/categoryDetails/:categoryId"
          element={<CategoryDetails t={t} />}
        />
        <Route path="/login" element={<Login />} />
        <Route path="/forgottenPassword" element={<ForgottenPassword />} />
        <Route path="/register" element={<Register setlogin={setlogin} />} />
        <Route path="/addressBook" element={<AddressBook />} />
        <Route path="/myAccounts" element={<MyAccounts />} />
        <Route path="/edit" element={<Edit setlogin={setlogin} />} />
        <Route path="/newslatter" element={<Newslatter />} />
        <Route path="/wishList" element={<WishList />} />
        <Route
          path="/search"
          element={
            <Search
              golobalKeyword={golobalKeyword}
              searchKeyword={searchKeyword}
              t={t}
            />
          }
        />

        <Route path="/logout" element={<Logout />} />

        <Route path="*" element={<Navigate to="/login" />} />
      </Routes>
    );
  }

  return (
    <div>
      <BrowserRouter>
        <Layout
          login={login}
          setlogin={setlogin}
          t={t}
          changeLn={changeLanguage}
          handleSectionRef={handleSectionRef}
          setHomePageSearchedResultProducts={setHomePageSearchedResultProducts}
        >
          {routes}
        </Layout>
      </BrowserRouter>
    </div>
  );
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = {
  checkAuth: authOperations.authStateCheck,
  setUser: authOperations.setAuthUser,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
