import React from "react";
import { Link } from "react-router-dom";
import b1 from "../../assets/images/b1.jpg";

const SingleBanner = ({ t }) => {
  return (
    <div className="singleBannerCardContainer">
      <img src={b1} class="img-fluid w-100" alt="Banner Image"></img>
      <div className="overlay">
        <h2 className="banner-title text-uppercase">{t("mcb")}</h2>
        <p className="banner-subtitle">{t("bansub")}</p>
        <div className="d-flex justify-content-start">
          <p className="banner-button">
            <Link to={"/productDetails"}>{t("spnow")}</Link>
          </p>
        </div>
      </div>
    </div>
  );
};

export default SingleBanner;
