import axios from "axios";
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";
import { connect } from "react-redux";
import "./Collops.css";


function CartAccordion({ cartItems, subTotal, user }) {
  const [show, setShow] = useState(false);

  const [email, setEmail] = useState("");

  const handleClose = () => setShow(false);
  const handleShow = () => {
    if (user.email) {
      handleEmailSubmit();
      return;
    } 
    setShow(true);
  };


  const handleEmailSubmit = () => {
    const quoteObj = {
      email: user.email || email,
      cartItems: cartItems,
      subtotal: subTotal,
      total: subTotal,
    };

    axios
      .post("https://faizainc-backend.techknowgram.net/api/quote", quoteObj)
      .then(function (response) {
        console.log(response);
        if (response.status === 200) {
          setEmail("");
          handleClose();
          alert("Quotation Generated Successfully, Please Check Email");
        }
      })
      .catch(function (error) {
        console.log(error);
        alert("Quotation Generation Failure");
      });
  };
  return (
    <>
      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Quotation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
              <Form.Label>Email address</Form.Label>
              <Form.Control
                type="email"
                placeholder="Enter your email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                autoFocus
              />
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" onClick={handleEmailSubmit}>
            Submit
          </Button>
        </Modal.Footer>
      </Modal>
      <Accordion defaultActiveKey="0">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Use Coupon Code</Accordion.Header>
          <Accordion.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <p>Enter you coupon here</p>
                </div>
                <div className="col-md-10 d-flex justify-content-center align-items-center">
                  <input
                    name="couponCode"
                    id="couponCode"
                    placeholder="Enter your coupon here"
                    type="text"
                  />
                  <button className="applyCoupon">APPLY COUPON</button>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
        <Accordion.Item eventKey="1">
          <Accordion.Header>Estimate Shipping & Taxes</Accordion.Header>
          <Accordion.Body>
            <div className="container">
              <p>Enter your destination to get a shipping estimate</p>
              <div className="row">
                <div className="col-md-2">
                  <p>Country</p>
                </div>
                <div className="col-md-10 d-flex justify-content-center align-items-center">
                  {/* <input className="shipping" placeholder="Grenada" type="text" /> */}
                  <select
                    className="shipping"
                    name="shippingCountry"
                    id="shippingCountry"
                  >
                    Shipping Country
                    <option value="japan">Japan</option>
                    <option value="france">France</option>
                    <option value="bangladesh">Bangladesh</option>
                    <option value="india">India</option>
                  </select>
                </div>
              </div>
              <div className="row py-3">
                <div className="col-md-2">
                  <p>Region / State</p>
                </div>
                <div className="col-md-10 d-flex justify-content-center align-items-center">
                  {/* <input
                  className="shipping"
                  placeholder="Saint George"
                  type="text"
                /> */}
                  <select
                    className="shipping"
                    name="shippingRegion"
                    id="shippingRegion"
                  >
                    Shipping Region
                    <option value="japan">Japan</option>
                    <option value="france">France</option>
                    <option value="bangladesh">Bangladesh</option>
                    <option value="india">India</option>
                  </select>
                </div>
              </div>
              <div className="row">
                <div className="col-md-2">
                  <p>Post Code</p>
                </div>
                <div className="col-md-10 d-flex justify-content-center align-items-center">
                  <input className="shipping" placeholder="1234" type="text" />
                </div>
              </div>

            </div>
          </Accordion.Body>
        </Accordion.Item>

        <Accordion.Item eventKey="2">
          <Accordion.Header>Use Gift Certificate</Accordion.Header>
          <Accordion.Body>
            <div className="container">
              <div className="row">
                <div className="col-md-2">
                  <p>Enter your gift certificate code here</p>
                </div>
                <div className="col-md-10 d-flex justify-content-center align-items-center">
                  <input
                    name="giftCertificate"
                    id="giftCertificate"
                    placeholder="Enter your gift certificate code here"
                    type="text"
                  />
                  <button className="applyCoupon">
                    APPLY GIFT CERTIFICATE
                  </button>
                </div>
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
      <div className="">
        <button onClick={handleShow} className="getQuotesBtn">
          Get Quotes
        </button>
      </div>
    </>
  );
};


const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
  };
};

const mapDispatchToProps = {}



export default connect(mapStateToProps, mapDispatchToProps)(CartAccordion);
