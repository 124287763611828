import React from 'react';
import Modal from 'react-bootstrap/Modal';
import { Link } from 'react-router-dom';
import "./Login.css";

const Login = ({ setlogin, show }) => {
    const handleClose = () => setlogin(false);
    return (
        <>
            <Modal show={show} onHide={handleClose}>
                <div className="mainmodal">
                    <i class="fa fa-user" aria-hidden="true"></i>
                    <h5 className='modalTitle'>SIGN IN OR REGISTER</h5>
                    <i onClick={handleClose} className='fa fa-close close'></i>
                </div>
                <div className="maindiv">
                    <div className="row">
                        <div className="col-md-6">
                            <div className="loginaccount">
                            <div className="mail">
                                <div class="input-group flex-nowrap ">
                                <span class="input-group-text fa fa-user" id="addon-wrapping" ></span>
                                    <input type="text" class="form-control" placeholder="E-mail Address" aria-label="Username" aria-describedby="addon-wrapping" />
                                </div>
                                <div class="input-group flex-nowrap ">
                                    <span class="input-group-text fa fa-passward" id="addon-wrapping" >@</span>
                                    <input type="text" class="form-control" placeholder="Passward" aria-label="Username" aria-describedby="addon-wrapping" />
                                </div>
                            </div>
                            <p style={{ marginLeft: "20px", padding: "5px" }}>Login with your social account</p>
                            <div className="icon1">
                                <a href="" style={{backgroundColor:"#dd4b39"}}>
                                    <i  class="fa fa-google fa-fw" aria-hidden="true"></i>
                                </a>
                                <a href="" style={{backgroundColor:"#3b5998"}}>
                                    <i style={{width:"15%"}} class="fa fa-facebook fa-fw" aria-hidden="true"></i>
                                </a>
                                <a href=""style={{backgroundColor:"#55acee"}}>
                                    <i  class="fa fa-twitter fa-fw" aria-hidden="true"></i>
                                </a>
                                <a href="" style={{backgroundColor:"#0e76a8"}}>
                                    <i  class="fa fa-linkedin fa-fw" aria-hidden="true"></i>
                                </a>
                                <a href="" > 
                                <p  className="hoverColor">Forgot the password</p>
                                </a>
                                
                                
                                <Link to='/register' type="button" onClick={handleClose} class="btn btn-outline-primary">Login</Link>
                            </div>
                            </div>
                        </div>
                        <div className="col-md-6">
                            <div className="createAccount">
                            <h5>NEW HERE?</h5>
                            <p>Registration is free and easy!</p>
                            <ul>
                                <li>Faster checkout</li>
                                <li>Save multiple shipping addresses</li>
                                <li>View and track orders and more</li>
                            </ul>
                            <Link to="/register"  type="button" onClick={handleClose} class="btn btn-outline-primary">CREATE ANY ACCOUNT</Link>
                            </div>
                        </div>
                    </div>

                </div>
            </Modal>


        </>

    )
}

export default Login