import React from 'react'

export const ShoppingCard = (props) => {
  return (
    <div className='newShoppingsCardContainer'>
      <i class={props.icon}></i>
      <div className="">
        <h5 className='text-center title'>{props.title}</h5>
        <p className='text-center pragrap'>{props.p}</p>
      </div>
    </div>
  )
}
