import React from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import CartAccordion from "../../Components/Collops/CartAccordion";
import CartBannerComponent from "../../Components/ProdectBanner/CartBannerComponent";
import "./index.css";

import { cartOperations } from "../../state/ducs/cart";
import { numberWithCommas } from "../../utils";

const ViewPage = ({ cartItems, changeProductQuantity }) => {
  const navigate = useNavigate();

  let totalQuantity = 0;
  let subTotal = 0;
  for (let index = 0; index < cartItems.length; index++) {
    const element = cartItems[index];
    totalQuantity += parseInt(element.quantity);
    subTotal += element.quantity * element.price;
  }

  const onProductQuantityChange = (e, product) => {
    const quantity = e.target.value;
    if (quantity < 1) {
      return;
    }
    changeProductQuantity(product, quantity);
  };

  const onContinueShoppingHandler = () => {
    navigate("/");
  };

  const onCheckoutHandler = () => {
    navigate("/checkout");
  };

  return (
    <div>
      <CartBannerComponent title="Shopping Cart" />
      <div className="container">
        <div className="row">
          <h1>Shopping Cart ( {totalQuantity} unit(s) ) </h1>
          <div class="container">
            <table class="table table-bordered">
              <thead>
                <tr>
                  <th className="text-center">Image</th>
                  <th>Product Name</th>
                  <th>Vendor Name</th>
                  <th>Quantity</th>
                  <th>Unit Price </th>
                  <th>Total</th>
                </tr>
              </thead>
              <tbody>
                {cartItems.length &&
                  cartItems.map((product) => (
                    <tr>
                      <td className="text-center">
                        <img
                          height={100}
                          width={100}
                          src={"https://ap.japanidokan.com/assets/images/products/"+product.photo}
                          alt=""
                        />
                      </td>
                      <td>{product.name}</td>
                      <td>{product.vendor_name}</td>
                      <td>
                        <input
                          type="number"
                          value={product.quantity}
                          name="productQuantity"
                          id="productQuantity"
                          onChange={(e) => onProductQuantityChange(e, product)}
                        />
                      </td>
                      <td>৳{numberWithCommas(product.price)}</td>
                      <td>
                        ৳
                        {numberWithCommas(
                          product.price * product.quantity
                        )}
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <div className="container">
        <h1>What would you like to do next?</h1>
        <p>
          Choose if you have a discount code or reward points you want to use or
          would like to estimate your delivery cost.
        </p>
        <div className="row">
          <CartAccordion cartItems={cartItems} subTotal={subTotal} />
        </div>
        <div className="row py-5 align-items-end">
          <div className="col-md-6">
            <button
              onClick={onContinueShoppingHandler}
              type="button"
              class="btn btn-danger"
            >
              Continue Shopping
            </button>
          </div>
          <div className="col-md-2"></div>
          <div className="col-md-4">
            <table class="table table-bordered">
              {" "}
              <tbody>
                <tr>
                  <td class="text-right">
                    <strong>Sub-Total:</strong>
                  </td>
                  <td class="text-right">৳{numberWithCommas(subTotal)}</td>
                </tr>{" "}
                <tr>
                  <td class="text-right">
                    <strong>Total:</strong>
                  </td>
                  <td class="text-right">৳{numberWithCommas(subTotal)}</td>
                </tr>{" "}
              </tbody>
            </table>
            <div className="buttonContainer">
              <button
                onClick={onCheckoutHandler}
                type="button"
                class="btn btn-secondary"
              >
                CHECKOUT
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    cartItems: state.cart,
  };
};

const mapDispatchToProps = {
  changeProductQuantity: cartOperations.changeQuantity,
};

export default connect(mapStateToProps, mapDispatchToProps)(ViewPage);
